import Vue from "vue";
import Vuex from "vuex";
import axios from "axios"; 
//import router from '../router'
import domtoimage from 'dom-to-image';
import qs from "querystring";
import moment from "moment"
import _ from "lodash"
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    items: [],
    images: [],
    revision: [],
    revisionDate: moment(),
    revisionIndex: 0,
    link: "",
    ceiling: {
      x: 3.1,
      y: 4.6,
      type: 0,
      tile: [
        {
          x: 0.3,
          y: 0.3
        },
        {
          x: 0.6,
          y: 0.6
        },
        { 
          x: 0.3,
          y: 0.6
        },
        {
          x: 0.2,
          y: 0.6
        },
        {
          x: 0.6,
          y: 1.2
        },
        {
          x: 0.3,
          y: 0.9
        }
      ],
    },  
    loading: false,
    title: "Новая композиция",
    backendUrl: "https://calc.potolki.ru/backend.php",
    groups: [],
    subgroups: [],
  },
  mutations: {
    SET_IMAGES: (s, images) => s.images = images,
    SET_LOADING: (s, loading) => s.loading = loading,
    SET_ITEMS: (s, data) => {
      s.items = data.data.items; s.ceiling = data.data.ceiling; s.title = "Композиция " + data.id
    },
    SET_REVISION: (s) => {
      s.revision.splice(s.revisionIndex, 0, s.items); 
    },
    SET_REVISION_INDEX: (s) => {
      const revision = localStorage.getItem("revision") ? JSON.parse(localStorage.getItem("revision")) : []; 
      let revisionData = _.orderBy(revision, (a) => moment(a.date), 'asc').filter(r => moment(r.date).isBefore(s.revisionDate));
      revisionData = revisionData[revisionData.length-1];
      
      s.revisionDate = revisionData.date;
      

      s.items = revisionData.items;  
 
      
       
    },
    CHANGE_TYPE: (s, type) => s.ceiling.type = type,
    SET_LINK: (s, link) => s.link = link,
    SET_GROUPS: (s, groups) => s.groups = groups,
    SET_SUBGROUPS: (s, groups) => s.subgroups = groups,  
    CHANGE_ITEMS: (s, {row, cell, active, img, options}) => {
      s.revisionDate = moment();
      s.items[row][cell].active = active;
      s.items[row][cell].img = img;
      s.items[row][cell].options = options; 
    }
  },
  actions: {
    async getImages({commit}, {group, subgroup}) {
      const {data: images} = await axios.post(this.state.backendUrl, {images: 1, group, subgroup});
      commit("SET_IMAGES", images); 
    },
    async save({commit}, id) {
      commit("SET_LOADING", true); 
      
      var node = document.getElementById('content');

      const _this = this;

      const name = prompt("Введите название");

      domtoimage.toPng(node)
      .then(async function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        //document.body.appendChild(img);
        //dispatch("getGrid", result.id);

        const formData = new FormData();

        formData.append("method", "save");
        formData.append("id", id);
        formData.append("data", qs.stringify({ceiling: _this.state.ceiling, items: _this.state.items}));
        formData.append("name", name);

       

        const b64toBlob = (base64) => fetch(`data:${base64}`).then(r => r.blob());

  const imageBlob = await b64toBlob(dataUrl);  

  formData.append("img", imageBlob);

  const {data: result} = await axios.post(_this.state.backendUrl, formData);


        commit("SET_LINK", result);


        commit("SET_LOADING", false);
      });

      //if(router.history.current.params.id != result.id) router.push("/" + result.id); 
   
    },
    async getGrid({commit}, id) {
      const {data: result} = await axios.post(this.state.backendUrl, {method: "getGrid", id: id});
      commit("SET_ITEMS", result); 
    },
    changeType({commit}, e) {
      commit("CHANGE_TYPE", e);
    },
    revisionChange({commit}) { 
      commit("SET_REVISION_INDEX");
    },
    changeCell({commit, state}, {img, active, cell, row, options}) {
      const revision = localStorage.getItem("revision") ? JSON.parse(localStorage.getItem("revision")) : [];
      revision.push({
        items: state.items,
        date: moment()
      }) 
      localStorage.setItem("revision", JSON.stringify(revision));  
      commit("CHANGE_ITEMS", {row, cell, active, img, options});  
      
  
          
    },
    async getGroups({commit}, subgroup = 0) {
      const {data: result} = await axios.post(this.state.backendUrl, {method: "getGroups", subgroup}); 
      console.log(subgroup)
      subgroup ? commit("SET_SUBGROUPS", result) : commit("SET_GROUPS", result); 
 
    },
    changeGroup({dispatch}, group) { 
      dispatch("getGroups", group[0]);
    },
    changeSubGroup({dispatch}, {group, subgroup}) {
      dispatch("getImages", {group: group[0], subgroup: subgroup[0]});
    }
  },
  getters: {
    items: s => s.items,
    images: s => s.images,
    title: s => s.title,
    loading: s => s.loading,
    ceiling: s => s.ceiling,
    revision: s => s.revision,
    link: s => s.link,
    groups: s => s.groups,
    subgroups: s => s.subgroups,
    revisionIndex: s => s.revisionIndex,
  },
  modules: {},
});
