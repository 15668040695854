import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import '../theme/index.css'
import "./plugins/element.js";
import VueDragDrop from 'vue-drag-drop';

Vue.use(VueDragDrop);
import DynamicStyle from 'vue-dynamic-style';
Vue.use(DynamicStyle, { /* config, see below */ });
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
