<template>
  <div id="app">

    <div class="app-top">
      
     
      <div class="app-title">
        <el-row type="flex" align="middle">
          <el-col :span="12">
            <div class="logo">
      <img src="https://www.potolki.ru/local/images/logo.png" alt="" width="100px">
     <div style="font-size: 20px; font-weight: 500;">Создай свой потолок</div>
    </div>
          </el-col>
          <el-col
            :span="12"
            justify="end"
            style="text-align: right"
            type="flex"
          >
            <el-button
              type="success"
              :loading="loading"
              @click="save($route.params.id)"
              >Сохранить композицию</el-button
            >
            <a href="/compositions/" class="link" target="_blank">Сохраненные композиции</a>
          </el-col>
        </el-row>
      </div>
      <div class="app-desc"><br>
        Здесь вы можете спроектировать следующие виды потолков: зеркальные, стеклянные, пескоструйные, кассетные, фотовитражные
      </div>
      <el-divider></el-divider>
      <el-row>
        <el-col>
          <strong>Вращение плитки (против часовой стрелки):</strong> наведите курсор на ячейку и сделайте
          двойной клик левой клавишей мышки<br /><br />
          <strong>Удаление плитки:</strong> наведите курсор на ячейку и нажмите
          правую клавишу мышки<br />
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="12">
          
          <el-form class="form" ref="form" :model="ceiling" label-width="120px" label-position="top">
            <el-row>
              <el-form-item style="max-width: 400px" label="Размер потолка (максимальный размер 11х11м)">
              <el-col :span="11">
                <el-input-number
                  v-model="ceiling.x"
                  :precision="2"
                  :step="0.1"
                  :max="10"
                ></el-input-number>
              </el-col>
              <el-col class="line" :span="2"
                ><i class="el-icon-close"></i
              ></el-col>
              <el-col :span="11">
                <el-input-number
                  v-model="ceiling.y"
                  :precision="2"
                  :step="0.1"
                  :max="10"
                ></el-input-number>
              </el-col>
            </el-form-item>
            </el-row>
            <el-row>
              <el-col :span="12">
            <el-form-item label="Размер модулей, мм">
              <el-select v-model="ceiling.type" @change="changeType($event)">
                <el-option
                  v-for="n of tSize"
                  :value="n.value"
                  :label="n.label"
                  :key="n.value"
                ></el-option>
              </el-select>
  
            </el-form-item>
            <el-form-item label="Опции">
              <el-radio-group v-model="options" size="small">
                <el-radio-button
                  label="Не выбрано"
                  :value="0"
                ></el-radio-button>
                <el-radio-button
                  label="Отверстие под светильник"
                  :value="2"
                ></el-radio-button>
              </el-radio-group>
            </el-form-item></el-col>
              <el-col :span="12" style="text-align:center"> <el-form-item label="Масштаб">
              <el-button-group>
                <el-button
                  type="primary"
                  icon="el-icon-minus"
                  @click="scale -= 0.1"
                ></el-button>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  @click="scale += 0.1"
                ></el-button>
              </el-button-group>
            </el-form-item>
            <el-form-item label="Смещение">
              <el-row type="flex" class="row-bg" justify="center">
                <el-button
                  @click="gridUpDown(true)"
                  icon="el-icon-top"
                  circle
                ></el-button>
              </el-row>
              <el-row type="flex" class="row-bg" justify="center">
                <el-button
                  @click="gridLeftRight(false)"
                  icon="el-icon-back"
                  circle
                ></el-button>
                <div class="lt" style="padding: 0px 30px"></div>
                <el-button
                  @click="gridLeftRight(true)"
                  icon="el-icon-right"
                  circle
                ></el-button>
              </el-row>
              <el-row type="flex" class="row-bg" justify="center">
                <el-button
                  @click="gridUpDown(false)"
                  icon="el-icon-bottom"
                  circle
                ></el-button>
              </el-row>
            </el-form-item></el-col>
            </el-row>
           
           
          </el-form>
          <el-divider></el-divider>
          <div class="app-info">
            <div>  <div>
              Размер модулей:
              <strong>{{ this.tSize[this.ceiling.type].label }}</strong>
            </div>
            <div>
              Количество модулей: <strong>{{ count }}</strong>
            </div>
            <div>
              Ориентировочная стоимость: <strong>{{ price }} руб.</strong>
            </div></div>
            <div>

              <el-button
        title="Шаг назад"
        @click="revisionChange();"
   
        ><i class="el-icon-refresh-left"></i></el-button
      ><br /><br />
            </div>
          
          </div>
        </el-col>
        <el-col :span="12" style="font-size: 15px">
          <el-cascader
            placeholder="Выберите вид потолка"
            v-model="group"
            :options="groups"
            @change="changeGroup(group)"
          ></el-cascader>
          <el-cascader
            placeholder="Выберите коллекцию"
            v-model="subgroup"
            :options="subgroups" 
            @change="changeSubGroup({group, subgroup})"
            v-if="group"
          ></el-cascader>
          <div class="images">
            <el-image
              style="cursor: pointer"
              @click="cellData.img = url"
              v-for="url in images"
              :key="url"
              :src="url"
              :title="gettitle(url)"
              
            ></el-image>
          </div>
        </el-col>
      </el-row>
    
    </div>
    <div
      class="container"
      :style="{
        transform: 'scale(' + scale + ')',
        transformOrigin: 'top center',
      }"
    >
      <div class="size-label">
        <div class="slX">{{ ceiling.x }} м</div>
        <div class="slY">{{ ceiling.y }} м</div>
        <!--<div class="slpX">{{decimalAdjust("ceil", ceiling.x%ceiling.tile[ceiling.type].x, 1)*10/2}}</div>
        <div class="slpY">{{decimalAdjust("ceil", ceiling.y%ceiling.tile[ceiling.type].y, 1)*10/2}}</div>-->
        <div
          class="partSize"
          :data-index="index"
          v-for="(p, index) of partSize"
          :key="index"
        >
          <div v-for="(i, index2) of p" :key="index2">{{ i }}</div>
        </div>
      </div>

      <table id="content">
        <tbody>
          <tr v-for="(row, index) in items" :key="index" :data-row="index">
            <td
              :class="[
                'cell',
                { pl: item.options == 'Планки' },
                { itemActive: item.active },
              ]"
              v-for="(item, i) in row"
              :key="i"
              :height="item.size.y"
              :width="item.size.x"
              :colspan="item.colspan"
              :style="{
                display: item.display,
                background: item.color,
                visibility: item.visibility,
                borderColor: item.options == 'Планки' ? color : '',
              }"
              @click="clickEvent(index, i, true, $event)"
              @contextmenu.prevent="clickEvent(index, i, false, $event)"
              :data-cell="i"
            >
              <drag
                @drag="dragEvent(index, i, ...arguments)"
                @dragend="dragEndEvent(index, i, ...arguments)"
                class="item"
                v-if="item.active"
                ><div
                  :style="{
                    transform: 'rotate(' + item.rotate + 'deg)',
                    backgroundImage: 'url(\'' + item.img + '\')',
                    height: item.size.y,
                    backgroundSize: item.p2,
                    backgroundPosition: item.bp,
                  }"
                  :class="['image']"
                  @dblclick.prevent="imgRotate($event, index, i)"
                  :src="item.img"
                  :data-src="item.img"
                  alt=""
                >
                  <div
                    class="slot"
                    v-if="item.options == 'Отверстие под светильник'"
                  ></div>
                </div>
              </drag>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
export default {
  name: "app",
  created() {
    localStorage.setItem("revision", "[]"); 
    //this.getImages();
    this.getGroups(); 
    this.changeGroup([this.group]);
    if (this.$route.params.id) this.getGrid(this.$route.params.id);
    else this.initGrid();

    console.log(this.gridSize);
    console.log(this.tileSize);
  },
  data() {
    return {
      containerWidth: 1300,
      price: 0,
      group: null,
      subgroup: null,
      last: null,
      scale: 1,
      color: "#0000FF",
      offset: {
        x: 0,
        y: 0,
      },
      options: "Не выбрано",
      partSize: [
        [50, 50],
        [50, 50],
        [50, 50],
        [50, 50],
      ],
      tSize: [
        {
          label: "300x300",
          value: 0,
        },
        {
          label: "600x600",
          value: 1,
        },
        {
          label: "300x600",
          value: 2,
        },
        {
          label: "200x600",
          value: 3,
        },
         {
          label: "600x1200",
          value: 4,
        },
         {
          label: "300x900",
          value: 5,
        },

      ],
      cellData: {
        active: false,
        img: "https://images.by.prom.st/68310681_w640_h640_plitka-potolochnaya-parizh-2.jpg",
        rotate: 0,
      },
    };
  },
  methods: {
    ...mapActions([
      "getImages",
      "save",
      "getGrid",
      "addRevision",
      "revisionChange",
      "getGroups",
      "changeGroup",
      "changeSubGroup"
    ]), 
    gettitle(url) {
      return url.replace("https://calc.potolki.ru/uploads/", "").replace(".jpg", "").replace(".JPG", "")
    },
    decimalAdjust(type, value, exp) {
      // Если степень не определена, либо равна нулю...
      if (typeof exp === "undefined" || +exp === 0) {
        return Math[type](value);
      }
      value = +value;
      exp = +exp;
      // Если значение не является числом, либо степень не является целым числом...
      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
        return NaN;
      }
      // Сдвиг разрядов
      value = value.toString().split("e");
      value = Math[type](
        +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp))
      );
      // Обратный сдвиг
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
    },
    changeType(e) {
      this.$store.dispatch("changeType", e);
      this.initGrid();
    },
    clickEvent(row, cell, active = true, event) {
      const slot = this.options == "Отверстие под светильник" && event.target.className == "image";

      console.log(cell, event);


      const isActive =
        ((event.target.className == "image" ||
          event.target.className == "slot") &&
          this.options == "Отверстие под светильник") ||
        false;
      if (isActive) {
        const slot = document.createElement("div");
        slot.className = "slot";
        if (event.target.closest(".item").querySelectorAll(".slot").length) {
          event.target
            .closest(".item")
            .querySelectorAll(".slot")
            .forEach((e) => e.remove());
        } else event.target.append(slot);
      }

      const fields = {
        row,
        cell,
        active,
        options:
          isActive && !event.target.querySelectorAll(".slot").length
            ? this.options
            : {},
      };

      if(!slot) fields.img =  this.cellData.img;
      else fields.img = event.target.dataset.src;

      this.$store.dispatch("changeCell", fields);
    },
    dragEvent(row, cell, transfer, event) {
      const el = document.elementFromPoint(event.pageX, event.pageY);
      [].forEach.call(document.querySelectorAll(".cell"), function (el) {
        el.classList.remove("drop");
      });
      if (el.classList.contains("cell")) {
        el.classList.toggle("drop");
        this.last = {
          row: el.parentElement.dataset.row,
          cell: el.dataset.cell,
        };
      }
    },
    dragEndEvent(row, cell) {
      this.$store.state.items[this.last.row][this.last.cell].active = true;
      this.$store.state.items[row][cell].active = false;
    },
    imgRotate(e, row, cell) {
      e.deltaY > 0
        ? (this.$store.state.items[row][cell].rotate += 90)
        : (this.$store.state.items[row][cell].rotate -= 90);
    },
    addPartY(items) {
      const p = parseInt(
        Math.ceil(
          this.ceiling.tile[this.ceiling.type].y * (this.gridSize.y % 1) * 1000
        ) / 2
      );
      console.log(
        this.ceiling.tile[this.ceiling.type].y * (this.gridSize.y % 1) * 1000
      );
      this.partSize[0][0] = p;
      this.partSize[1][0] = p;
      this.partSize[2][0] = p;
      this.partSize[3][0] = p;
      return items.push(
        [...Array(Math.floor(this.gridSize.x)).keys()].map(() => ({
          ...this.cellData,
          //part: true,
          prt: true,
          size: {
            x: (this.tileSize.x * (this.gridSize.x % 1)) / 2,
            y: (this.tileSize.y * (this.gridSize.y % 1)) / 2,
          },
        }))
      );
    },
    numRound(num) {
      let d = Math.floor(num),
        i = num % d;
      return i < 0.25 ? d : i < 0.75 ? d + 0.5 : d + 1;
    },
    addPartX(items) {
      const p = parseInt(
        (this.ceiling.tile[this.ceiling.type].x *
          (this.gridSize.x % 1) *
          1000) /
          2
      );

      this.partSize[0][1] = p;
      this.partSize[1][1] = p;
      this.partSize[2][1] = p;
      this.partSize[3][1] = p;

      for (let row in items) {
        items[row].unshift({
          ...this.cellData,
          part: true,
          prt: true,
          size: {
            x: (this.tileSize.x * (this.gridSize.x % 1)) / 2,
            y: (this.tileSize.y * (this.gridSize.y % 1)) / 2,
          },
        });
        items[row].push({
          ...this.cellData,
          part: true,
          prt: true,
          size: {
            x: (this.tileSize.x * (this.gridSize.x % 1)) / 2,
            y: (this.tileSize.y * (this.gridSize.y % 1)) / 2,
          },
        });
      }
      items[0][0].bp = "right bottom";
      items[0][items[items.length - 1].length - 1].bp = "left bottom";
      items[items.length - 1][items[items.length - 1].length - 1].bp =
        "left top";
      items[items.length - 1][0].bp = "right top";

      items[0][0].p2 = "500%";
      items[0][items[items.length - 1].length - 1].p2 = "500%";
      items[items.length - 1][items[items.length - 1].length - 1].p2 = "500%";
      items[items.length - 1][0].p2 = "500%";

      return items;
    },
    initGrid() {
      const items = [];
      if (!Number.isInteger(this.gridSize.y)) {
        this.addPartY(items);
      } else {
        this.partSize.map((m) => (m[0] = 0));
      }
      for (let row = items.length; row < Math.floor(this.gridSize.y); row++) {
        items[row] = [];
        for (let cell = 0; cell < Math.floor(this.gridSize.x); cell++) {
          items[row][cell] = {
            ...this.cellData,
            size: {
              x: this.tileSize.x,
              y: this.tileSize.y,
            },
          };
        }
      }
      if (!Number.isInteger(this.gridSize.y)) this.addPartY(items);
      if (!Number.isInteger(this.gridSize.x)) {
        this.addPartX(items);
      } else {
        this.partSize.map((m) => (m[1] = 0));
      }
      this.$store.state.items = items;
    },
    offsetCells(coord, dir, offset) {
      const items = this.$store.state.items;

      const partX = (this.tileSize.x * (this.gridSize.x % 1)) / 2;
      const partXFull = this.tileSize.x * (this.gridSize.x % 1);
      const partY = (this.tileSize.y * (this.gridSize.y % 1)) / 2;
      const partYFull = this.tileSize.y * (this.gridSize.y % 1);

      console.log(coord, dir, offset);

      if (coord == "x") {
        items.map((row) => {
          if (dir == 1 && row[0].size.x == partX) {
            this.partSize[0][1] = 100;
            this.partSize[1][1] = 0;
            this.partSize[2][1] = 100;
            this.partSize[3][1] = 0;
            row[0].size.x = partXFull;
            row[row.length - 1].size.x = 1;
          } else if (dir == -1 && row[0].size.x == partXFull) {
            this.partSize[0][1] = 50;
            this.partSize[1][1] = 50;
            this.partSize[2][1] = 50;
            this.partSize[3][1] = 50;
            row[0].size.x = partX;
            row[row.length - 1].size.x = partX;
          } else if (dir == -1 && row[0].size.x == partX) {
            this.partSize[0][1] = 0;
            this.partSize[1][1] = 100;
            this.partSize[2][1] = 0;
            this.partSize[3][1] = 100;
            row[0].size.x = 1;
            row[row.length - 1].size.x = partXFull;
          } else if (dir == 1 && row[0].size.x == 1) {
            this.partSize[0][1] = 50;
            this.partSize[1][1] = 50;
            this.partSize[2][1] = 50;
            this.partSize[3][1] = 50;
            row[0].size.x = partX;
            row[row.length - 1].size.x = partX;
          }
        });
      } else if (coord == "y") {
        if (dir == 1 && items[0][0].size.y == partY) {
          items[0].map((c) => (c.size.y = 1));
          items[items.length - 1].map((c) => (c.size.y = partYFull));
          this.partSize[0][0] = 0;
          this.partSize[1][0] = 0;
          this.partSize[2][0] = 100;
          this.partSize[3][0] = 100;
        } else if (dir == -1 && items[0][0].size.y == partY) {
          this.partSize[0][0] = 100;
          this.partSize[1][0] = 100;
          this.partSize[2][0] = 0;
          this.partSize[3][0] = 0;
          items[0].map((c) => (c.size.y = partYFull));
          items[items.length - 1].map((c) => (c.size.y = 1));
        } else if (
          (dir == -1 && items[0][0].size.y == 1) ||
          (dir == 1 && items[0][0].size.y == partYFull)
        ) {
          this.partSize[0][0] = 50;
          this.partSize[1][0] = 50;
          this.partSize[2][0] = 50;
          this.partSize[3][0] = 50;
          items[0].map((c) => (c.size.y = partY));
          items[items.length - 1].map((c) => (c.size.y = partY));
        }
      }

      /*
      if(coord == "x" && dir == 1) {
         items.map(r => {
            r[0].size.x = partX
            r[r.length-1].size.x = 0;
         }); 
      } else if (coord == "x" && dir == -1) {
         
      } else if (coord == "y" && dir == 1) {
           items.push(items.shift()); 
      } else if(coord == "y" && dir == -1) {
          items.unshift(items.pop());
      }*/

      for (let row = 0; row <= items.length - 1; row++) {
        for (let cell = 0; cell <= items[row].length - 1; cell++) {
          if (
            cell == 0 &&
            items[row][cell + 1] &&
            items[row][cell].part == items[row][cell + 1].part
          ) {
            items[row][cell].colspan = 2;
          }
        }
      }
    },
    gridLeftRight(type) {
      if ((this.offset.x == -1 && !type) || (this.offset.x == 1 && type))
        return;
      if (!type) {
        this.offset.x -= 1;
        this.offsetCells("x", -1, this.offset.x);
      } else {
        this.offset.x += 1;
        this.offsetCells("x", 1, this.offset.x);
      }
    },
    gridUpDown(type) {
      if ((this.offset.y == -1 && !type) || (this.offset.y == 1 && type))
        return;
      if (!type) {
        this.offset.y -= 1;
        this.offsetCells("y", -1, this.offset.y);
        //вниз
      } else {
        this.offset.y += 1;
        this.offsetCells("y", 1, this.offset.y);
      }
    },
  },
  computed: {
    ...mapGetters([
      "items",
      "images",
      "title",
      "loading",
      "ceiling",
      "revision",
      "link",
      "groups",
      "subgroups",
      "revisionIndex"
    ]),
    tileSize() {
      return {
        x: (this.containerWidth - this.gridSize.x) / this.gridSize.x,
        y: [0, 1].includes(this.$store.state.ceiling.type)
          ? (this.containerWidth - this.gridSize.x) / this.gridSize.x
          : ((this.containerWidth - this.gridSize.x) / this.gridSize.x) * 2,
      };
    },
    gridSize() {
      return {
        x: this.ceiling.x / this.ceiling.tile[this.ceiling.type].x,
        y: this.ceiling.y / this.ceiling.tile[this.ceiling.type].y,
      };
    },
    count() {
      let count = 0;
      this.items.map((r) =>
        r.filter((c) => c.active && !c.prt).map(() => count++)
      );
      return count;
    },
    grid() {
      return _.cloneDeep(this.$store.state.items);
    },
  },
  watch: {
    grid(newValue, oldValue) {
      if (oldValue.length) {
        //this.$store.state.revision.push(oldValue);
      }
    },
    tSize(n) {
      this.$store.state.ceiling.type = n;
    },
    gridSize: function () {
      console.log(this.gridSize);
      this.initGrid();
    },
  },
};
</script>

<style lang="stylus">
* {
  box-sizing: border-box;
}

.bg {
  background: blue;
  height: 100%;
}

.drop {
  background: #33333314;
}
#content {
  position: relative;
  z-index:999;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
}

.grid {
  display: grid;
  gap: 1px;
}

.cell {
}

table {
  border-collapse: collapse;
}

td {
  border: 1px solid #DCDFE6;
  padding: 0px;
  overflow: hidden;
}

.item {
  width: 100%;
  height: 100%;
  max-height: -webkit-fill-available;
}

.cuttingLineX {
  width: 1px;
  height: 100%;
  position: absolute;
  background: red;
  top: 0;
  left: 20px;
}

.btns {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px 0px;
}

.item img {
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
}

.cell.part {
  margin: 0 auto;
  margin-right: 0;
}

.form {
 
}

.app-top {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px 0px;
}

.app-title {
  font-size: 26px;
}

.el-form-item .el-col {
  text-align: center;
}

.app-info {
  line-height: 26px;
}

.image {
  background-size: cover;
  width: 100%;
  height: 100%;
}

.images img {
  width: 165px;
  height: 165px;
  padding: 10px;
  margin-bottom: 20px;
}

.images {
      max-height: 500px;
    overflow-y: auto;
}

.slot {
     width: 25px;
    height: 25px;
    position: absolute;
    left: calc(50% - 12.5px);
    top: calc(50% - 12.5px);
    background: gray;
    border-radius: 50%;
    border: 2px solid #333;
}

.pl {
  border: 5px solid;
}

.size-label {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slX {
  position: absolute;
  top: -20px;
  left: 50%;
}

.slpX {
  position: absolute;
  top: -20px;
  left:0;
}

.slY {
  top: 50%;
  left: -40px;
  transform: rotate(-90deg);
  position: absolute;
}

.slpY {
  top: 0;
  left: -40px;
  transform: rotate(-90deg);
  position: absolute;
}


.link {
      font-size: 16px;
    display: block;
    color: #67c23a;
    margin-top: 20px;
}


.partSize {
  position: absolute;
}

.partSize[data-index="0"] {
  top: 0;
  left: -35px;
}

.partSize[data-index="0"] div:nth-child(2) {
      position: absolute;
    left: 35px;
    top: -23px;
}

.partSize[data-index="1"] div:nth-child(2) {
     position: absolute;
    right: 35px;
    top: -23px;
}




.partSize[data-index="1"] {
  top: 0;
  left: 100%;
}

.partSize[data-index="2"] {
  bottom: 0;
  left: -35px;
}


.partSize[data-index="2"] div:nth-child(2) {
     position: absolute;
    left: 35px;
    bottom: -23px;
}


.partSize[data-index="3"] div:nth-child(2) {
  position: absolute;
    right: 21px;
    bottom: -23px;
}



.partSize[data-index="3"] {
  bottom: 0;
  left: 100%;
}
.logo {
  display: flex;
  align-items: center
}

.logo img {
  margin-right: 20px
}
.app-info {
  display: flex;
  justify-content: space-between
}
</style>
